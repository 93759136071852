/* BLOCK: Component Container */

.topContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 100dvh;
	background-color: #000;
	background-image: url("@assets/background-image.jpg");
	/* background-image: url("../../assets/Starred Space Background.gif"); */
	/* background-image: url("https://www.wallpaperflare.com/static/1008/234/575/earth-minimalism-rocket-white-wallpaper.jpg"); */
	background-size: cover;
	background-position: center;
}

.top-content-wrapper {
	/* padding: 0px 2rem; */
	width: 100%;
	height: 100%;
	max-width: 1440px;

	display: flex;
	align-items: center;
}

/* BLOCK: Navigation */

.menu-container {
	position: fixed;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 999;
	background-color: #00000077;
	transition: background-color 1s linear;
}

.menu-top-bg {
	background-color: #000000cc;
}

.menu-top-wrapper .menu-top-container li span {
	transition: color 2s linear;
}

.menu-top-bg .menu-top-wrapper .menu-top-container li span {
	color: #EAEAEA;
}

.logo-wrapper {
	margin-left: 2rem;
	max-width: 20%;
	padding: .5rem 0px;
}

.logo {
	font-size: 2.2rem;
}

.menu-top-wrapper {
	margin-right: 2rem;
	max-width: 80%;
}

/* BLOCK: Content */

.top-content {
	padding: 2rem 3rem;
	flex: 1;
}

.top-content-cta-title {
	font-size: calc(1.2dvw + 1.5rem);
}

.top-content-cta-title h1 {
	margin: 0;
}

.top-content-cta-small {
	/* text-align: center; */
	display: flex;
	justify-content: center;
	background-color: #000000CC;
	border-radius: 1.5rem;
}

.top-content-cta-small p {  
	font-size: calc(0.5dvw + 1rem);
	max-width: 75%;
}

/* .top-content-cta-button-container button {
	background: #F7F7F7;
	color: #333; 
	font-size: 1.5rem;
	text-transform: uppercase;
	margin-top: 1.2rem;
	font-weight: bold;
	outline: none;
	stroke: none;
	border: 0.5rem solid #11111111;
	padding: 2rem;
	border-radius: 0.5rem 2rem 0.5rem 2rem;
	cursor: pointer; 
} */

.top-content-cta-button-container button {
    background: linear-gradient(45deg, #283B40, #0E2940, #402238);
    color: #F7F7F7;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: 1.2rem;
    font-weight: bold;
    outline: none;
    border: 0.5rem solid rgba(255, 255, 255, 0.2);
    padding: 2rem;
    border-radius: 0.5rem 2rem 0.5rem 2rem;
    cursor: pointer;
    transition: background 1s ease-in-out, box-shadow 0.3s ease-in-out;
}

.top-content-cta-button-container button:hover {
	background: linear-gradient(45deg, #0C3559, #402238, #283B40);
    animation: glow 3s infinite alternate;
}

@keyframes glow {
    from {
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
    }
    to {
        box-shadow: 0 0 5px rgba(255, 255, 255, 1);
    }
}



/* ================================================= */

/* .logo {
	position: absolute;
	top: 2rem;
	left: 1.5rem;
	font-size: 2.5rem;
	font-weight: bold;
	color: white;
}

.menuButton,
.toUpButton {
	position: fixed;
	right: 1.5rem;
	border: none;
	cursor: pointer;
	z-index: 999;
}

.menuButton {
	background: none;
	top: 2rem;
}

.toUpButton {
	bottom: 2rem;
	background: #fafafabb;
	border-radius: 50%;
}

.menuIcon {
	color: #fafafa;
}

.toUpIcon {
	color: #111;
}

.callToAction {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: calc(1.5rem + 0.7vmin);
	text-shadow: 0.5rem 0.5rem 1rem #000, -0.5rem -0.5rem 1rem #222;
	text-align: center;
}

.footerText {
	position: absolute;
	bottom: 20px;
	left: 20px;
	font-size: 14px; / * ajuste conforme necessário * /
	color: white; / * ajuste conforme necessário * /
} */
