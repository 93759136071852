.contactContainer {
	display: flex;
	align-items: center;
	justify-content: baseline;
	flex-direction: column;

	min-height: 70dvh;
	text-align: center;
    padding: 7rem 4rem;
}

.contactContainer .top-content-wrapper {
	width: 100%;
	height: 100%;

	max-width: 1440px;

	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 7rem 4rem;
}

.contactContainer .contact-top {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.contactContainer .contact-top h2 {
	font-size: calc(1.5dvw + 3.5rem);
	margin: 0;
	margin-bottom: 3.5rem;
}

.contactContainer .contact-top span {
	font-size: calc(1dvw + 1.5rem);
	display: block;
	margin-bottom: -1.75rem;
}

.contactContainer .contactGrid {
	position: relative;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex: 1;
	flex-wrap: wrap;
}

.contactContainer .contactGrid form {
    width: 80%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: calc(10px + 1rem);
}

.contactContainer .form-group {
	display: flex;
	flex-direction: column;
    text-align: left;
}

.contactContainer .form-submit-btn {
	grid-column: span 1; /* Span full width in single column layout */
}

.contactContainer .form-submit-btn button {
	min-width: 200px;
	width: calc(10dvw + 5rem);
}

/* =========================================== */

/* .slider-wrap {
      background: tomato;
      display: flex;
      align-items: center;
  } */

/* input.test {
	appearance: none;
	background: transparent;
	height: 2rem;
}

input.test::-webkit-slider-thumb {
	appearance: none;
	width: 1rem;
	height: 1.8rem;
	background-color: aquamarine;
	position: relative;
} */

/* =========================================== */

.contactContainer .contact-form {
	background-color: #2b2b2b;
	color: #ffffff;
	padding: 20px;
	max-width: 600px;
	margin: 0 auto;
	border-radius: 10px;
}

.contactContainer .contact-form h2 {
	text-align: center;
	margin-bottom: 20px;
}

.contactContainer .form-group {
	margin-bottom: 15px;
}

.contactContainer .form-group label {
	display: block;
	margin-bottom: 5px;
}

.contactContainer .form-group input,
.contactContainer .form-group textarea {
	width: calc(100% - 20px);
	padding: 10px;
	border: none;
	border-radius: 5px;
	background-color: #3c3c3c;
	color: #ffffff;
}

.contactContainer .form-group input[type="range"] {
	width: 100%;
}

.contactContainer .form-group span {
	display: block;
	margin-top: 5px;
}

.contactContainer .contactContainer button {
	width: 100%;
	padding: 15px;
	border: none;
	border-radius: 5px;
	background-color: #333;
	color: #ffffff;
	font-size: 16px;
	cursor: pointer;
}

.contactContainer .contactContainer button:hover {
	background-color: #222;
}

/* 3 columns for large screens */
@media (min-width: 1024px) {
	.contactContainer .contactGrid form {
		grid-template-columns: repeat(3, 1fr);
        justify-content: space-around;
	}
	.contactContainer .form-submit-btn {
		grid-column: span 3;
	}
}

/* 2 columns for medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
	.contactContainer .contactGrid form {
		grid-template-columns: repeat(2, 1fr);
	}
	.contactContainer .form-submit-btn {
		grid-column: span 2;
	}
}
