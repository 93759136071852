.menu-top-container {
	width: 100%;
}

.menu-top-container nav ul {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.menu-top-container nav ul li,
.menu-top-container nav ul li span {
	text-decoration: none;
	list-style: none;
}

.menu-top-container nav ul li {
	margin: 0px 1rem;
	position: relative;
	color: #EEEEEE;
}

.menu-top-container nav ul li::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1.5px;
	background-color: transparent;
	transform-origin: bottom left;
	transition: transform 0.35s ease-out, background-color 0.35s ease-out;
	transform: scaleX(0);
}

.menu-top-container nav ul li:hover {
	cursor: pointer;
	color: #FFFFFF;
	text-shadow: 0px 0px 7px #FAFAFA;
}

.menu-top-container nav ul li:hover::after {
	transform: scaleX(1);
	background-color: #fafafabb;
}

.menu-top-container nav ul li span {
	padding: 0.5rem;
	color: #FAFAFA;
    text-transform: uppercase;
}
