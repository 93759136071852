@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  text-align: center;
  background-color: #222222;
  /* min-width: 100%; // Antes estava assim*/
  min-width: 700px;
  width: 100%;
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: calc(1rem + 0.5vmin);
  color: #FAFAFA;
}