.servicesContainer {
	display: flex;
	align-items: center;
	justify-content: baseline;
	flex-direction: column;
	min-height: 70dvh;
	text-align: center;
	padding: 7rem 4rem;
	position: relative;
}

/* .servicesContainer::before {
	content: "";
	position: absolute;
	top: 0;
	left: 10%;
	right: 10%;
	height: 3px;
	border-radius: 100%;
	background-color: #FAFAFA55;
	transform-origin: top center;
	align-self: center;
} */

.service-top {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: baseline;
}

.service-top h2 {
	font-size: calc(1.5dvw + 3.5rem);
	margin: 0;
}

.service-top span {
	font-size: calc(1dvw + 1.5rem);
	display: block;
	margin-bottom: -1.75rem;
}

.serviceGrid {
	position: relative;
	width: 100%;
	
	/* display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 2rem; */
	
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex: 1;
	flex-wrap: wrap;
}

/* .serviceGrid::before {
	content: "";
	position: absolute;
	top: 0;
	left: 35%;
	right: 35%;
	height: 1px;
	background-color: #FAFAFA33;
	transform-origin: top center;
	align-self: center;
} */

.serviceCard {
	position: relative;
	display: flex;
	align-items: baseline;
	flex-direction: column;
	text-align: left;
	scale: 0.9;
	padding: 2rem;
	width: 440px;
	min-width: 300px;
	min-height: 600px;
	/* border: 1px solid #ccc; */
	background: #99999911;
	border-radius: 28px;
	color: #FAFAFABB;
	text-decoration: none;
}

.serviceCard:hover {
	cursor: pointer;
	scale: 1;
}

.serviceCard .serviceCard-top {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	text-align: center;
}

.serviceCard .serviceCard-top h3 {
	font-size: calc(1dvw + 1.2rem);
	text-transform: uppercase;
}

.serviceCard .serviceCard-top span {
	position: absolute;
	top: 0;
	right: 0;
	font-size: calc(2.5dvw + 15rem);
	font-weight: bolder;
	margin-top: -3rem;
	margin-right: 2rem;
	opacity: 0.05;
}

.serviceCard p {
	font-size: calc(0.5dvw + 1rem);
	text-shadow: 0px 0px 2px #000, 0px 0px 2px #000, 0px 0px 2px #000;
}

/* .arrowLink {
	display: inline-block;
	margin-top: 1rem;
	text-decoration: none;
	color: #FAFAFABB;
	font-weight: bold;
	font-size: 48px;
} */
