/* BLOCK: Footer */

.container {
	/* border-top: 1px solid #eaeaea; */
	min-height: 30dvh;
	width: 100%;
	position: relative;
	padding-top: 8rem;
	padding-bottom: 2rem;
}

.container::before {
	content: "";
	position: absolute;
	top: 0;
	left: 10%;
	right: 10%;
	height: 3px;
	border-radius: 100%;
	background-color: #FAFAFA55;
	transform-origin: top center;
	align-self: center;
}

.footerColumns {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.bottom {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding-top: 5%;
}

.socialContainer {
	display: flex;
	flex-direction: row;
}

.socialContainer .social {
	display: flex;
	align-items: center;
	opacity: 0.8;
}

.socialContainer .social a {
    color: #FAFAFA;
}

.socialContainer .social:hover {
	opacity: 1;
	scale: 1.1;
	cursor: pointer;
}

.socialContainer .social:not(:first-child) {
	margin-left: 1rem;
}

/* BLOCK: SiteMap */

.siteMapColumn {
    padding-left: 2rem;
    padding-right: 2rem;
}

.siteMapColumn .columnTitle {
	text-transform: uppercase;
	font-size: medium;
	text-align: left;
	font-weight: 700;
	margin-bottom: 3.5rem;
}

.siteMapColumn .sitemapItems {
    padding-left: 0;
}

.siteMapColumn .sitemapItems .sitemapItem {
	/* font-family: "Roboto Mono", monospace; */
	text-transform: uppercase;
	font-weight: 300;
	text-transform: capitalize;
	text-align: left;
	text-overflow: ellipsis;
	letter-spacing: 2px;
	list-style: none;
	margin-top: 15%;
}

.siteMapColumn .sitemapItems .sitemapItem a {
	color: #ffffff;
	position: relative;
	text-decoration: none;
}

.siteMapColumn .sitemapItems .sitemapItem a::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1.5px;
	background-color: transparent;
	transform-origin: bottom left;
	transition: transform 0.35s ease-out, background-color 0.35s ease-out;
	transform: scaleX(0);
}

.siteMapColumn .sitemapItems .sitemapItem a:hover::after {
	transform: scaleX(1);
	background-color: #fafafabb;
}

.siteMapColumn .sitemapItems .sitemapItem a:not(:hover)::after {
	transition-delay: 0.35s;
}

/* BLOCK: Newsletter */

.newsletterColumn {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    width: 55%;
}


.newsletterColumn .columnTitle {
	text-transform: uppercase;
	font-size: medium;
	text-align: left;
	font-weight: 700;
	margin-bottom: 3.5rem;
}

.newsletterColumn .emailInputContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* font-family: "Roboto Mono", monospace; */
    border-bottom: 1.5px solid #FAFAFABB;
}

.newsletterColumn .emailInput {
    width: 100%;
    text-align: left;
	/* text-transform: uppercase; */
	font-weight: 500;
    font-size: 1.5rem;
    background: none;
    outline: none;
    border: none;
    color: #FAFAFABB;
	padding: 0 0 0.25rem 0.5rem;
}

.newsletterColumn .emailInput::placeholder {
	color: #FAFAFA;
    opacity: 0.2;
	text-transform: uppercase;
	font-size: 1.4rem;
}

.newsletterColumn .emailSubmit {
    text-align: center;
	text-transform: uppercase;
	font-weight: 500;
    font-size: 0.9rem;
    background: none;
    outline: none;
    border: none;
    color: #FAFAFABB;
    letter-spacing: 2.5px;
}

.newsletterColumn .emailSubmit:hover {
    color: #FAFAFA;
    cursor: pointer;
}
