/* .aboutContainer {
	display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    text-transform: uppercase;
    color: #FAFAFA;
    font-size: calc(3dvw + 5rem);

	min-height: 70dvh;
} */
.aboutContainer {
    display: flex;
	flex-wrap: wrap;
    align-items: center;
	justify-content: space-evenly;
	padding: 50px 20px;
	/* background-color: #f9f9f9; */
	text-align: center;
    min-height: 25dvh;
}

.aboutContainer .about-column {
    background: linear-gradient(45deg, #283B40, #0E2940, #402238);
	flex: 1;
	padding: 20px;
	min-width: 300px;
	max-width: 400px;
	margin: 10px;
	/* background: #33333355; */
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.aboutContainer .about-column:hover {
	transform: translateY(-10px);
	box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

.aboutContainer .about-icon {
	font-size: 2.5rem;
	color: #FAFAFA;
	margin-bottom: 10px;
}

.aboutContainer .about-title {
	font-size: 2rem;
	margin-bottom: 10px;
	color: #FAFAFA;
}

.aboutContainer .about-paragraph {
	font-size: 1rem;
	line-height: 1.6;
	color: #EDEDED;
	margin: 0 auto 20px auto;
	max-width: 350px;
}
